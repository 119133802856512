import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import Select from 'react-select';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import SelectMulti from '../../components/SelectMulti';
import { GiCancel } from 'react-icons/gi';
import ActionButton from '../../components/ActionButton';
import { BsTrash } from 'react-icons/bs';
import { FaPrint } from 'react-icons/fa';
import Swal from 'sweetalert2';
import PrintRecipiet from './PrintRecipiet';
import { LuFileSpreadsheet } from 'react-icons/lu';
import TabHead from '../../components/TabHead';

const Historiques = ({ isVisible = true, onClose, facture }) => {
    const [loading, setLoading] = useState(false);
    const [paiements, setPaiements] = useState([]);
    const [selected, setSelected] = useState([]);
    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
    const [selectedPaiement, setSelectedPaiement] = useState(null);
    const [showPrint, setShowPrint] = useState(false);

    useEffect(() => {
        getPaiements();
    }, []);

    const navigate = useNavigate();

    const getPaiements = () => {
        setLoading(true);
        axiosClient.get('/facture/paiements/' + facture.id)
            .then(response => {
                setPaiements(response.data.paiements);
                setLoading(false);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Merci de verifier les informations a saisies!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    const supprimer = (paiement) => {
        Swal.fire({
            title: "Confirmation",
            text: "Etes vous sûre de supprimer cette element ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2196F3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete('/paiement/' + paiement.id)
                    .then(response => {
                        toast.success('Opération effectuée avec succès!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        getPaiements();
                    }).catch(err => {
                        const response = err.response;
                        if (err.response.status === 401) {
                            setToken(null);
                        } else {
                            if (response.status === 422) {
                                setErrors(response.data.errors);
                                setLoading(false);
                                toast.error('Une erreur est survenue!', {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                });
                            }
                            else {
                                if (response.status === 403) {
                                    navigate('/403');
                                } else {
                                    if (response.status === 404) {
                                        navigate('/404');
                                    }
                                    else {
                                        navigate('/500');
                                    }
                                }
                            }
                        }

                    })
            }
        });
    }
    const closePrint = () => {
        setShowPrint(false);
        setSelectedPaiement(null);
    }
    if (!isVisible) {
        return null
    }

    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto rounded-xl'>
                        <div className=''>
                            <Header icon={<LuFileSpreadsheet />} title="Historiques de Paiement" />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }
                        {loading ? <Loading /> :
                            <>
                                <table className='table-auto' >
                                    <thead>
                                        <tr>
                                            <th><TabHead left="Date" right="التاريخ"/></th>
                                            <th><TabHead left="Description" right="وصف"/></th>
                                            <th><TabHead left="Montant payé" right="المبلغ المدفوع"/></th>
                                            <th><TabHead left="Reste" right="المتبقي"/></th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paiements && paiements.map(o => (
                                            <tr className='hover:bg-gray-200' key={o.id}>
                                                <td>{o.datePaiement}</td>
                                                <td>{o.description}</td>
                                                <td>{o.montant}</td>
                                                <td>{o.montantReste}</td>

                                                <td>
                                                    <div className="flex gap-3 justify-between">
                                                        <ActionButton onClick={() => {
                                                            setSelectedPaiement(o);
                                                            setShowPrint(true);
                                                        }} color="bg-blue-500" icon={<FaPrint />} textColor="text-white" title="Imprimer" />
                                                        <ActionButton onClick={() => supprimer(o)} color="bg-red-500" icon={<BsTrash />} textColor="text-white" title="Suprimer" />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))

                                        }
                                    </tbody>
                                </table>
                                {
                                    showPrint ? <PrintRecipiet paiement={selectedPaiement} onClose={closePrint} facture={facture} /> : <></>
                                }
                            </>
                        }
                        <div className='flex justify-end items-end'>
                            <div className=' m-4 w-70 justify-between'>
                                <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Historiques