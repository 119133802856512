import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import Alert from '../../components/Alert'
import { IoIosSearch } from 'react-icons/io';

import { useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaRegFileExcel } from 'react-icons/fa6';
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import Loading from '../../components/Loading';
import ActionButton from '../../components/ActionButton';
import AddAlimentation from './AddAlimentation';
import Swal from 'sweetalert2';
import { BiArrowToTop } from 'react-icons/bi';
import TabHead from '../../components/TabHead';


const Alimentations = () => {
    const [errors, setErrors] = useState(null);

    const [loading, setLoading] = useState(false);
    const [alimentations, setAlimentations] = useState([]);
    const [showAddAlimentation, setShowAddAlimentation] = useState(false);
    const { token, setToken, user } = useStateContext();
    const tableRef = useRef();
    const [req, setReq] = useState({
        DateDebut: '',
        DateFin: '',
        exp: '',
    });
    const navigate = useNavigate();


    useEffect(() => {

    }, [])


    const closeAddAlimentation = () => {
        setShowAddAlimentation(false);
    }
    const getAlimentations = () => {
        setLoading(true);
        axiosClient.get('/getAlimentations?DateDebut=' + req.DateDebut + '&DateFin=' + req.DateFin + '&exp=' + req.exp)
            .then(response => {
                setAlimentations(response.data.alimentations);
                setLoading(false);
                setErrors(null);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    const supprimer = (alimentation) => {
        Swal.fire({
            title: "Confirmation",
            text: "Etes vous sûre de supprimer cette element ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2196F3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete('/alimentation/' + alimentation.id)
                    .then(response => {
                        toast.success('Opération effectuée avec succès!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        getAlimentations();
                    }).catch(err => {
                        const response = err.response;
                        if (err.response.status === 401) {
                            setToken(null);
                        } else {
                            if (response.status === 401) {
                                setToken(null);
                            }
                            else {
                                if (response.status === 403) {
                                    navigate('/403');
                                } else {
                                    if (response.status === 404) {
                                        navigate('/404');
                                    }
                                    else {
                                        navigate('/500');
                                    }
                                }
                            }
                        }

                    })
            }
        });
    }


    return (
        <>
            <Header icon={<BiArrowToTop />} title="Alimentations" />
            {errors &&
                Object.keys(errors).map(key => (
                    <Alert key={key} message={errors[key][0]} />
                ))
            }
            <div className='flex gap-2 w-full flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between items-center'>
                <Input label="Date debut" labelAr="من" type="date" value={req.DateDebut} onChange={ev => setReq({ ...req, DateDebut: ev.target.value })} required="required" />
                <Input label="Date fin" labelAr="الى" type="date" value={req.DateFin} onChange={ev => setReq({ ...req, DateFin: ev.target.value })} required="required" />
                <Input label="Expression" labelAr="تعبير" type="text" onChange={ev => setReq({ ...req, exp: ev.target.value })} />
                <div className=' flex gap-2 mt-8'>
                    <Button onClick={() => getAlimentations()} color="bg-yellow-500" icon={<IoIosSearch />} size="20" text="Chercher" textColor="text-white" />
                    <DownloadTableExcel
                        filename="Alimentations"
                        sheet="alimentations"
                        currentTableRef={tableRef.current}
                    >
                        <Button color="bg-green-500" icon={<FaRegFileExcel />} size="20" text="Exporter" textColor="text-white" />

                    </DownloadTableExcel>
                </div>
            </div>
            {
                user.role && user.role.name === 'caissier' ? <div className="flex my-4">
                    <Button onClick={() => setShowAddAlimentation(true)} color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="Ajouter" textColor="text-white" />
                </div>
                    : <></>
            }
            <div className="mt-4">
                <h1 className='text-green-700 font-bold'>Total= {alimentations.reduce((n,{montant})=>n+parseFloat(montant),0)} MRU</h1>
            </div>
            <div className='flex mt-3 overflow-auto'>
                <table ref={tableRef} className='table-auto' >
                    <thead>
                        <tr>
                            <th><TabHead left="Date" right="التاريخ" /></th>
                            <th><TabHead left="Montant" right="المبلغ" /></th>
                            <th><TabHead left="Resp" right="المسؤول" /></th>
                            <th><TabHead left="Note" right="ملاحظة" /></th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="6"><Loading /></td></tr></tbody> :
                        <tbody>
                            {alimentations.map(o => (
                                <tr>
                                    <td>{o.dateAlimentation}</td>
                                    <td>{o.montant}</td>
                                    <td>{o.responsable}</td>
                                    <td>{o.note}</td>
                                    <td><ActionButton onClick={()=>{supprimer(o)}} color="bg-red-500" icon={<BsTrash />} textColor="text-white" title="Supprimer" /></td>
                                </tr>
                            ))

                            }
                        </tbody>
                    }

                </table>
            </div>
            {
                showAddAlimentation ?<AddAlimentation onClose={closeAddAlimentation}/>: <></>
            }

        </>
    )
}

export default Alimentations