import React from 'react'

const TabHead = ({left,right}) => {
  return (
    <div className="flex flex-col text-sm justify-center">
      <span>{right}</span>
      <span>{left}</span>
    </div>
  )
}

export default TabHead