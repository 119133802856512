import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import Alert from '../../components/Alert'
import { IoIosSearch } from 'react-icons/io';

import { useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaRegFileExcel } from 'react-icons/fa6';
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import Loading from '../../components/Loading';
import ActionButton from '../../components/ActionButton';
import Swal from 'sweetalert2';
import { FiShoppingCart } from 'react-icons/fi';
import { GrValidate } from 'react-icons/gr';
import { FaExclamation } from 'react-icons/fa';
import TabHead from '../../components/TabHead';


const Observations = () => {
    const [errors, setErrors] = useState(null);

    const [loading, setLoading] = useState(false);
    const [observations, setObservations] = useState([]);
    const [showAddObservation, setShowAddObservation] = useState(false);
    const { token, setToken, user } = useStateContext();
    const tableRef = useRef();
    const [req, setReq] = useState({
        DateDebut: '',
        DateFin: '',
        exp: '',
    });
    const navigate = useNavigate();

    const supprimer = (observation) => {
        Swal.fire({
            title: "Confirmation",
            text: "Etes vous sûre de supprimer cette element ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2196F3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete('/observation/' + observation.id)
                    .then(response => {
                        toast.success('Opération effectuée avec succès!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        getObservations();
                    }).catch(err => {
                        const response = err.response;
                        if (err.response.status === 401) {
                            setToken(null);
                        } else {
                            if (response.status === 401) {
                                setToken(null);
                            }
                            else {
                                if (response.status === 403) {
                                    navigate('/403');
                                } else {
                                    if (response.status === 404) {
                                        navigate('/404');
                                    }
                                    else {
                                        navigate('/500');
                                    }
                                }
                            }
                        }

                    })
            }
        });
    }
    const valider = (observation) => {
        Swal.fire({
            title: "Confirmation",
            text: "Etes vous sûre de valider cette observation?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2196F3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.put('/valider-observation/' + observation.id)
                    .then(response => {
                        toast.success('Opération effectuée avec succès!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                    }).catch(err => {
                        const response = err.response;
                        if (err.response.status === 401) {
                            setToken(null);
                        } else {
                            if (response.status === 401) {
                                setToken(null);
                            }
                            else {
                                if (response.status === 403) {
                                    navigate('/403');
                                } else {
                                    if (response.status === 404) {
                                        navigate('/404');
                                    }
                                    else {
                                        navigate('/500');
                                    }
                                }
                            }
                        }

                    })
            }
        });
    }

    useEffect(() => {

    }, [])


   
    const getObservations = () => {
        setLoading(true);
        axiosClient.get('/observations?DateDebut=' + req.DateDebut + '&DateFin=' + req.DateFin + '&exp=' + req.exp)
            .then(response => {
                setObservations(response.data.observations);
                setLoading(false);
                setErrors(null);
                console.log(response.data.observations);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }


    return (
        <>
            <Header icon={<FaExclamation />} title="Observations" />
            {errors &&
                Object.keys(errors).map(key => (
                    <Alert key={key} message={errors[key][0]} />
                ))
            }
            <div className='flex gap-2 w-full flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between items-center'>
                <Input label="Date debut" type="date" value={req.DateDebut} onChange={ev => setReq({ ...req, DateDebut: ev.target.value })} required="required" />
                <Input label="Date fin" type="date" value={req.DateFin} onChange={ev => setReq({ ...req, DateFin: ev.target.value })} required="required" />
                <Input label="Expression" type="text" onChange={ev => setReq({ ...req, exp: ev.target.value })} />
                <div className=' flex gap-2 mt-8'>
                    <Button onClick={() => getObservations()} color="bg-yellow-500" icon={<IoIosSearch />} size="20" text="Chercher" textColor="text-white" />
                    <DownloadTableExcel
                        filename="Observations"
                        sheet="observations"
                        currentTableRef={tableRef.current}
                    >
                        <Button color="bg-green-500" icon={<FaRegFileExcel />} size="20" text="Exporter" textColor="text-white" />

                    </DownloadTableExcel>
                </div>
            </div>
            {
                user.role && user.role.name === 'caissier' ? <div className="flex my-4">
                    <Button onClick={() => setShowAddObservation(true)} color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="Ajouter" textColor="text-white" />
                </div>
                    : <></>
            }
            
            <div className='flex mt-3 overflow-auto'>
                <table ref={tableRef} className='table-auto' >
                    <thead>
                        <tr>
                            <th><TabHead left="Date" right="التاريخ"/> </th>
                            <th><TabHead left="Matricule" right="رقم التسجيل"/></th>
                            <th><TabHead left="Élève" right="الطالب"/></th>
                            <th><TabHead left="Classe" right="القسم"/></th>
                            <th><TabHead left="Observation" right="الملاحظة"/></th>
                            <th><TabHead left="Auteur" right="الكاتب"/></th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="6"><Loading /></td></tr></tbody> :
                        <tbody>
                            {observations.map(o => (
                                <tr>
                                    <td>{o.dateObservation}</td>
                                    <td>{o.matricule}</td>
                                    <td>{o.eleve}</td>
                                    <td>{o.classe}</td>
                                    <td>{o.content}</td>
                                    <td>{o.auteur}</td>
                                    <td>
                                        <div className="flex gap-2">
                                            <ActionButton onClick={() => { valider(o) }} color="bg-green-500" icon={<GrValidate />} textColor="text-white" title="Supprimer" />
                                            <ActionButton onClick={() => { supprimer(o) }} color="bg-red-500" icon={<BsTrash />} textColor="text-white" title="Supprimer" />
                                        </div>
                                    </td>
                                </tr>
                            ))

                            }
                        </tbody>
                    }

                </table>
            </div>


        </>
    )
}

export default Observations