import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { GiCancel, GiTakeMyMoney } from 'react-icons/gi';
import { IoIosSave } from 'react-icons/io';

const Dispense = ({ isVisible=true, onClose,facture, }) => {
    const [loading, setLoading] = useState(false);
    
    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
    const [dispense,setDispense]=useState({
        'montant':facture.dispense,
        'facture_id':facture.id,
    })
    
    useEffect(() => {
        
    }, []);
    
    
    const navigate = useNavigate();

    const onSubmit = () => {

        setErrors(null);
        setLoading(true);

        axiosClient.post('/dispense/save', dispense)
            .then((response) => {
                setLoading(false);
                setErrors(null);
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                onClose();
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Merci de verifier les informations a saisies!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }
    if (!isVisible) {
        return null
    }

    return (
        <>
        <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
            <div className='h-screen flex justify-center items-center'>
                <div className='flex-col bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto rounded-xl'>
                    <div className=''>
                        <Header icon={<GiTakeMyMoney />} title="Dispense Facture" />
                    </div>
                    {errors &&
                        Object.keys(errors).map(key => (
                            <Alert key={key} message={errors[key][0]} />
                        ))
                    }
                   
                        <>
                                <div className='flex-col w-full justify-evenly '>
                                    <div className='mr-2'>
                                        <Input label="Total" labelAr="المجموع" readOnly="readOnly" type="number" value={facture.total}  required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Deja Payé" labelAr="المدفوع مسبقا"  readOnly="readOnly" type="number" value={facture.payee}  required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Reste" labelAr="لمطالب به" readOnly="readOnly" type="number" value={facture.total - facture.dispense - facture.payee} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Dispense" labelAr="الإعفاء"  type="number" value={dispense.montant} onChange={ev => setDispense({ ...dispense, montant: ev.target.value })} required="required" />
                                    </div>
                                </div> 
                        </>
                    
                    <div className='flex  justify-end items-end'>
                        <div className=' flex gap-3 m-2  justify-between'>
                            {loading ? <Loading /> :
                                    <Button icon={<IoIosSave />} onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />

                                }
                            <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Dispense