import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { GiCancel } from 'react-icons/gi';
import { FaCalendarAlt } from 'react-icons/fa';
import TabHead from '../../components/TabHead';

const EleveEmploie = ({ student, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [emploies, setEmploies] = useState([]);

    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
    const navigate = useNavigate();

    const getEmploies = () => {
        setLoading(true);
        axiosClient.get('/eleveEmploie/' + student.id)
          .then((data) => {
            setEmploies(data.data.emploies);
            setLoading(false);
            setErrors([]);
          }).catch(err => {
            const response = err.response;
            if (err.response.status === 401) {
              setToken(null);
            } else {
              if (response.status === 422) {
                setErrors(response.data.errors);
              }
              else {
                if (response.status === 403) {
                  navigate('/403');
                } else {
                  if (response.status === 404) {
                    navigate('/404');
                  }
                  else {
                    navigate('/500');
                  }
                }
              }
            }
            setLoading(false);
          })
    }

      useEffect(()=>{
        getEmploies();
    },[]);

    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col  max-h-screen overflow-y-auto bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto md:w-1/2 lg:w-1/2 xl:w-1/2 rounded-xl'>
                        <div className=''>
                            <Header icon={<FaCalendarAlt />} title={student.eleve} />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }
                         <div className="flex w-full justify-end">
                                <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                            </div>
                        <div className='mt-3 w-full overflow-x-auto'>
                            <table className='table-auto'>
                                <thead>
                                    <tr>
                                        <th><TabHead left="Jour" right="اليوم"/></th>
                                        <th><TabHead left="Matière" right="المادة"/></th>
                                        <th><TabHead left="Heure" right="التوقيت"/></th>
                                    </tr>
                                </thead>
                                {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="9"><Loading /></td></tr></tbody> :
                                    (

                                        <tbody>
                                            {emploies && emploies.map(o => (
                                                <tr key={o.id}>
                                                    <td>
                                                        <div className="flex justify-between">
                                                        <span>{o.jour.libelleFr}</span>
                                                        <span>{o.jour.libelleAr}</span>
                                                        </div>
                                                    </td>
                                                    <td>{o.matiere.matiere.libelle}</td>
                                                    <td>{o.heureDebut} | {o.heureFin}</td>
                                                </tr>
                                            ))

                                            }
                                        </tbody>
                                    )
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EleveEmploie