import React, { useEffect, useState } from 'react'
import { Button, Header, Input } from '../../components'
import { useNavigate, useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import Loading from '../../components/Loading';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import axiosClient from '../../axios-client';
import { BsPlusCircleFill } from 'react-icons/bs';
import DropDownButton from '../../components/DropDownButton';
import AddFacture from '../factures/AddFacture';
import Historiques from '../factures/Historiques';
import AddPaiement from '../factures/AddPaiement';
import Dispense from '../factures/Dispense';
import Textarea from '../../components/Textarea';
import Swal from 'sweetalert2';
import { PiStudentBold } from 'react-icons/pi';
import TabHead from '../../components/TabHead';

const Compte = () => {
    const { id } = useParams();
    const [factures, setFactures] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showHistoriques, setShowHistoriques] = useState(false);
    const [showAddPaiement, setShowAddPaiement] = useState(false);
    const [showDispense, setShowDispense] = useState(false);
    const [selectedFacture, setSelectedFacture] = useState(null);

    const [errors, setErrors] = useState(null);
    const { token, setToken, user } = useStateContext();
    const navigate = useNavigate();
    const [inscription, setInscription] = useState({
        id: '',
        eleve: '',
        montant: '',
        reduction: 1,
        telParent: '',
        rim: '',
        nni: '',
        genre: '',
        dateNaissance: '',
        commentaire: '',
        classe: {},
    });

    useEffect(() => {
        getInscriptionInfo();
        getFacture();

    }, []);

    const closeAdd = () => {
        setShowAdd(false);
        setLoadingInfo(true);
        getInscriptionInfo();
        getFacture();
    }

    const closeHistorique = () => {
        setSelectedFacture(null);
        setShowHistoriques(false);
        getInscriptionInfo();
        getFacture();
    }
    const closeAddPaiement = () => {
        setShowAddPaiement(false);
        setSelectedFacture(null);
        getInscriptionInfo();
        getFacture();
    }
    const closeDispense = () => {
        setShowDispense(false);
        setSelectedFacture(null);
        getInscriptionInfo();
        getFacture();
    }
    const getFacture = () => {
        axiosClient.get('/compte/factures/' + id)
            .then(response => {
                setFactures(response.data.factures);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }
    const supprimerFacture = (facture) => {
        setErrors(null);
        Swal.fire({
            title: "Confirmation",
            text: "Etes vous sûre de supprimer cette element ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2196F3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete('/facture/' + facture.id)
                    .then(response => {
                        toast.success('Opération effectuée avec succès!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        getFacture();
                    }).catch(err => {
                        const response = err.response;
                        if (err.response.status === 401) {
                            setToken(null);
                        } else {
                            if (response.status === 422) {
                                setErrors(response.data.errors);
                            }
                            else {
                                if (response.status === 403) {
                                    navigate('/403');
                                } else {
                                    if (response.status === 404) {
                                        navigate('/404');
                                    }
                                    else {
                                        navigate('/500');
                                    }
                                }
                            }
                        }

                    })
            }
        });
    }
    const getInscriptionInfo = () => {
        setLoadingInfo(true);
        axiosClient.get('/getInscription/' + id)
            .then(response => {
                setInscription(response.data.inscription);
                setSelectedValue(response.data.inscription.classe);
                setLoadingInfo(false);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }
    return (
        <>
            <Header icon={<PiStudentBold />} category='Page' title='Compte Elève' />
            {errors &&
                Object.keys(errors).map(key => (
                    <Alert key={key} message={errors[key][0]} />
                ))
            }
            {loadingInfo ? <Loading /> :
                <div className='flex-col '>
                    <div className="flex flex-col h-auto w-full  overflow-auto ">
                        <div className='mb-2'>
                            {
                                user.role && user.role.name === 'caissier' ?
                                    <div className="flex justify-between ">
                                        <Button color="bg-blue-500" onClick={() => setShowAdd(true)} icon={<BsPlusCircleFill />} size="20" text="Creer" textColor="text-white" />

                                    </div>
                                    : <></>
                            }

                        </div>
                        <div className='mb-36'>
                            <div className="flex w-full justify-between">
                                <span>Facutes</span>
                                <span>الفواتير</span>
                            </div>
                            <table className='table-auto' >
                                <thead>
                                    <tr>
                                        <th><TabHead left="Description" right="وصف"/></th>
                                        <th><TabHead left="Total" right="المجموع"/></th>
                                        <th><TabHead left="Dispense" right="الإعفاء"/></th>
                                        <th><TabHead left="Payé" right="المدفوع"/></th>
                                        <th><TabHead left="Reste" right="الباقي"/></th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {factures && factures.map(o => (
                                        <tr className='hover:bg-gray-200' key={o.id}>
                                            <td>{o.description}</td>
                                            <td>{o.total}</td>
                                            <td>{o.dispense}</td>
                                            <td>{o.payee}</td>
                                            <td>{o.total - o.dispense - o.payee}</td>
                                            <td>
                                                {
                                                    user.role && user.role.name === 'caissier' ?
                                                        <DropDownButton actions={[{
                                                            'libelle': "Payer",
                                                            'onClick': () => {
                                                                setSelectedFacture(o);
                                                                setShowAddPaiement(true);
                                                            }
                                                        },
                                                        {
                                                            'libelle': "Historiques",
                                                            'onClick': () => {
                                                                setSelectedFacture(o);
                                                                setShowHistoriques(true);
                                                            }
                                                        },
                                                        {
                                                            'libelle': "Dispense",
                                                            'onClick': () => {
                                                                setSelectedFacture(o);
                                                                setShowDispense(true);
                                                            }
                                                        },
                                                        {
                                                            'libelle': "Supprimer",
                                                            'onClick': () => {
                                                                supprimerFacture(o);
                                                            }
                                                        },
                                                        ]} />
                                                        :
                                                        <DropDownButton actions={[
                                                            {
                                                                'libelle': "Historiques",
                                                                'onClick': () => {
                                                                    setSelectedFacture(o);
                                                                    setShowHistoriques(true);
                                                                }
                                                            },
                                                            {
                                                                'libelle': "Dispense",
                                                                'onClick': () => {
                                                                    setSelectedFacture(o);
                                                                    setShowDispense(true);
                                                                }
                                                            },
                                                            {
                                                                'libelle': "Supprimer",
                                                                'onClick': () => {
                                                                    supprimerFacture(o);
                                                                }
                                                            },
                                                        ]} />

                                                }
                                            </td>
                                        </tr>
                                    ))

                                    }
                                </tbody>
                            </table>
                        </div>
                        {
                            showHistoriques ? <Historiques onClose={closeHistorique} facture={selectedFacture} /> : <></>
                        }
                        {
                            showAddPaiement ? <AddPaiement onClose={closeAddPaiement} facture={selectedFacture} /> : <></>
                        }
                        {
                            showDispense ? <Dispense onClose={closeDispense} facture={selectedFacture} /> : <></>
                        }
                        {
                            showAdd ? <AddFacture onClose={closeAdd} inscription={id} mensualite={inscription.montant} /> : <></>
                        }
                    </div>
                    <div className="flex flex-wrap  md:flex-nowrap lg:flex-nowrap xl:flex-nowrap ">
                        <div className="flex-col w-full h-full  bg-slate-100 p-1">
                            <Input readOnly="readOnly" labelAr="الطالب" label="Elève" type="text" value={inscription.eleve} />
                            <Input readOnly="readOnly" labelAr="رقم التسجيل" label="Matricule" type="text" value={inscription.matricule} />
                            <Input readOnly="readOnly" labelAr="القسم" label="Classe" type="text" value={inscription.classe.libelle} />
                            <Input readOnly="readOnly" labelAr="رقم هاتف الوكيل" label="Téléphone" type="text" value={inscription.telParent} />
                            <Input readOnly="readOnly" labelAr="واتس آب" label="Whatsapp" type="text" value={inscription.whatsapp} />
                            <Input readOnly="readOnly" labelAr="تلقرام" label="Telegram" type="text" value={inscription.telegram} />
                            <Input readOnly="readOnly" labelAr="أرقام أخرى" label="Autre Tel" type="text" value={inscription.autreTel} />
                        </div>
                        <div className="flex-col w-full h-full bg-slate-100 p-1">
                            <Input readOnly="readOnly" labelAr="تاريخ التسجيل" label="Date d'inscription" type="text" value={inscription.dateInscription} />
                            <Input readOnly="readOnly" labelAr="مجموع الإعفاءات" label="Total dispense" type="text" value={factures.reduce((n, { dispense }) => n + parseFloat(dispense), 0)} />
                            <Input readOnly="readOnly" labelAr="مجموع الباقي" label="Total reste" type="text" value={factures.reduce((n, { reste }) => n + parseFloat(reste), 0)} />
                            <Textarea readOnly="readOnly" rows={6} label="Commentaire" labelAr="ملاحظة" type="text" value={inscription.commentaire} />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Compte