import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { GiCancel } from 'react-icons/gi';
import { IoIosSave } from 'react-icons/io';
import Select from 'react-select';
import { FiShoppingCart } from 'react-icons/fi';

const AddDepense = ({ isVisible = true, onClose }) => {
    const [loading, setLoading] = useState(false);

    const { token, setToken } = useStateContext();
    const [errors, setErrors] = useState(null);
    const [categories, setCategories] = useState(null);
    const [selectedCategorie, setSelectedCategorie] = useState(null);
    const [inputValueCategorie, setValueCategorie] = useState('');


    const [depense, setDepense] = useState({
        montant: 0,
        categorie: '',
        beneficiaire: '',
        titre: '',
        note: ''
    });

    useEffect(() => {
        getCategories();
    }, []);

    const handleInputChangeCategorie = value => {
        setValueCategorie(value);
    };

    // handle selection


    // handle selection
    const handleChangeCategorie = value => {
        setSelectedCategorie(value);
        setDepense({ ...depense, categorie: value.id });

    }
    const navigate = useNavigate();
    const getCategories = () => {
        axiosClient.get('/categories')
            .then((data) => {
                setCategories(data.data.categories);
            })
    }
    const onSubmit = () => {

        setErrors(null);
        setLoading(true);

        axiosClient.post('/depense/save', depense)
            .then((response) => {
                setLoading(false);
                setErrors(null);
                toast.success('Opération effectuée avec succès!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                onClose();
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    if (!isVisible) {
        return null
    }

    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto rounded-xl'>
                        <div className=''>
                            <Header icon={<FiShoppingCart />} title="Ajoute une dépense" />
                        </div>
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }

                        <>
                            <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-3'>
                                <div className='flex-col w-full justify-evenly '>
                                    <div className='mr-2'>
                                        <Input label="Montant" labelAr="المبلغ" type="number" value={depense.montant} onChange={ev => setDepense({ ...depense, montant: ev.target.value })} required="required" />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Titre" labelAr="العنوان" type="text" value={depense.titre} onChange={ev => setDepense({ ...depense, titre: ev.target.value })} />
                                    </div>
                                    <div className='mr-2'>
                                        <Input label="Bénéficiaire" labelAr="المستفيد" type="text" value={depense.beneficiaire} onChange={ev => setDepense({ ...depense, beneficiaire: ev.target.value })} required="required" />
                                    </div>

                                </div>
                                <div className='flex-col w-full justify-evenly '>
                                    <div className="flex-col w-full">
                                        <div className="flex justify-between">
                                            <label className="block text-sm font-medium leading-6 text-gray-900">Catégorie <span className='text-red-500'> *</span></label>
                                            <label className="block text-sm font-medium leading-6 text-gray-900"> <span className='text-red-500'> *</span>الفئة</label>
                                        </div>

                                        <Select className='mt-2 outline-1 ' placeholder="Categorie" cacheOptions defaultOptions value={selectedCategorie} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={categories} onInputChange={handleInputChangeCategorie} onChange={handleChangeCategorie} />
                                    </div>
                                </div>
                            </div>
                        </>


                        <div className='flex  justify-end items-end'>
                            <div className=' flex gap-3 m-2  justify-between'>
                                {loading ? <Loading /> :
                                    <Button icon={<IoIosSave />} onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />

                                }
                                <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddDepense