import React, { useState } from 'react';
import { Button, Header, Input } from '../../components';
import Loading from '../../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import Select from 'react-select';
import axiosClient from '../../axios-client';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';
import { TbMath } from 'react-icons/tb';

const AddMatiere = ({ isVisible, onClose }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getLangue();
  }, [])

  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [inputValue, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [errors, setErrors] = useState(null);
  const { token, setToken } = useStateContext()
  const [matiere, setMatiere] = useState({
    id: '',
    code: '',
    libelle: '',
    langue: '',

  })

  // handle input change event
  const handleInputChange = value => {
    setValue(value);
  };

  // handle selection
  const handleChange = value => {
    setSelectedValue(value);
    setMatiere({ ...matiere, langue: value.id })

  }


  const getLangue = () => {
    axiosClient.get('/langues')
      .then((data) => {
        setItems(data.data.langues);
        setLoading(false);
      }).catch(() => {

      })
  }


  const onSubmit = () => {

    setErrors(null);
    setLoading(true);

    axiosClient.post('/matiere/save', matiere)
      .then((response) => {
        setLoading(false);
        setErrors(null);
        onClose();
      }).catch(err => {
        const response = err.response;
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            setLoading(false);
            toast.error('Une erreur est survenue!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })
  }
  if (!isVisible) {
    return null
  }

  return (
    <>
      <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
        <div className='h-screen flex justify-center items-center'>
          <div className='flex-col bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto rounded-xl'>
            <div className=''>
              <Header icon={<TbMath />} title="Ajouter une Matiere" />
            </div>
            {errors &&
              Object.keys(errors).map(key => (
                <Alert key={key} message={errors[key][0]} />
              ))
            }
            {loading ? <Loading /> :
              <div className='flex-col justify-evenly '>
                <div className='mr-2'>
                  <Input labelAr="الرمز" label="Code" type="text" onChange={ev => setMatiere({ ...matiere, code: ev.target.value })} required="required" />
                </div>
                <div className='mr-2'>
                  <Input labelAr="الإسم" label="Libelle" type="text" onChange={ev => setMatiere({ ...matiere, libelle: ev.target.value })} required="required" />
                </div>
                <div className='flex-col'>
                  <div className="flex justify-between">
                    <label className="block text-sm font-medium leading-6 text-gray-900">Langue <span className='text-red-500'> *</span></label>
                    <label className="block text-sm font-medium leading-6 text-gray-900"> <span className='text-red-500'> *</span>لغة التدريس</label>
                  </div>
                  <Select className='mt-2 outline-1 ' placeholder="Langue" cacheOptions defaultOptions value={selectedValue} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={items} onInputChange={handleInputChange} onChange={handleChange} />
                </div>
              </div>}
            <div className='flex justify-end items-end'>
              <div className=' m-4 w-70 justify-between'>
                <Button onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Annuler" />
                <Button onClick={onSubmit} color="ml-1 bg-green-500" textColor="text-white" text="Enregistrer" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </>

  )
}

export default AddMatiere