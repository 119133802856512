import React from 'react'

const StudentMenuComponent = ({text,textAr,colors,onclick,icon}) => {
  return (
    <div className={"flex gap-2 w-full my-2 border px-4 py-3 rounded relative hover:cursor-pointer "+colors} onClick={()=>onclick()}>
        <div className=" text-3xl">
            {icon}
        </div>
        <div className=" flex w-full text-lg font-bold justify-between">
            <span>{text}</span>
            <span>{textAr}</span>
        </div>
    </div>
  )
}

export default StudentMenuComponent