import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../../axios-client';
import Loading from '../../components/Loading';
import { useStateContext } from '../../contexts/ContextProvider';
import ActionButton from '../../components/ActionButton';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Alert from '../../components/Alert';
import { GiCancel } from 'react-icons/gi';
import { LuMousePointerClick } from 'react-icons/lu';
import { FaExclamation } from 'react-icons/fa';
import AddObservation from '../observations/AddObseravtions';

const AddAbsence = ({ selectedEmploie, onClose }) => {
    const navigate = useNavigate();
    const [devoir, setDevoir] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const { setToken, user } = useStateContext();
    const [eleves, setEleves] = useState([]);
    const [selectedEleve, setSelectedEleve] = useState(null);
    const [showAddObservation,setShowAddObservation]=useState(false);
    
    const tableRef = useRef(null);
    const [req, setReq] = useState({
        note: '',
        observation: '',
        eleve: '',
    });

    const getEleves = () => {
        setLoading(true);
        axiosClient.get('/eleves/' + selectedEmploie.classe.id)
            .then((data) => {
                setEleves(data.data.eleves);
                setLoading(false);
            }).catch(err => {
                navigate('/500');

            })
    }

    const signaler = (eleve) => {
        Swal.fire({
            title: "Confirmation",
            text: "Etes vous sûre de signaler "+eleve.eleve+" comme absent(e) ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2196F3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.post('/abscence/save', {eleve:eleve.id,emploie:selectedEmploie.id})
                    .then(response => {
                        toast.success('Opération effectuée avec succès!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                    }).catch(err => {
                        const response = err.response;
                        if (err.response.status === 401) {
                            setToken(null);
                        } else {
                            if (response.status === 422) {
                                setErrors(response.data.errors);
                            }
                            else {
                                if (response.status === 403) {
                                    navigate('/403');
                                } else {
                                    if (response.status === 404) {
                                        navigate('/404');
                                    }
                                    else {
                                        navigate('/500');
                                    }
                                }
                            }
                        }

                    })
            }
        });
    }

    const closeAddObservation=()=>{
        setShowAddObservation(false);
        setSelectedEleve(null);
    }
    useEffect(() => {
        getEleves();
    }, []);
    return (
        <>
            <div className=' fixed inset-0 h-full bg-black bg-opacity-25 backdrop-blur-sm  '>
                <div className='h-screen flex justify-center items-center'>
                    <div className='flex-col max-h-screen overflow-y-auto bg-white lg:p-10 xl:p-10  p-2 md:p-10 w-auto md:w-1/2 lg:w-1/2 xl:w-1/2 rounded-xl'>
                        <Header category='Page' icon={<LuMousePointerClick />} title={'Absence ' + selectedEmploie.classe.libelle + ' ' + selectedEmploie.matiere.matiere.libelle} titleAr="الغياب" />
                        {errors &&
                            Object.keys(errors).map(key => (
                                <Alert key={key} message={errors[key][0]} />
                            ))
                        }
                        <div className='flex-col '>
                            <div className="flex justify-end items-end">
                                <Button icon={<GiCancel />} onClick={() => onClose()} color="bg-red-500" textColor="text-white" text="Fermer" />
                            </div>
                            <div className='mt-3 w-full overflow-x-auto'>
                                <table ref={tableRef} className='table-auto'>
                                    <thead>
                                        <tr>
                                            <th>Matricule</th>
                                            <th>Eleve</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="9"><Loading /></td></tr></tbody> :
                                        (

                                            <tbody>
                                                {eleves && eleves.map(o => (
                                                    <tr key={o.id}>
                                                        <td>{o.matricule}</td>
                                                        <td>{o.eleve}</td>
                                                        <td>
                                                            <div className="flex justify-center w-full items-center gap-2">
                                                                <ActionButton onClick={() => signaler(o)} color="bg-red-500" icon={<LuMousePointerClick />} textColor="text-white" title="Absent(e)" />
                                                                <ActionButton onClick={() => {
                                                                    setSelectedEleve(o);
                                                                    setShowAddObservation(true);
                                                                }} color="bg-yellow-500" icon={<FaExclamation />} textColor="text-white" title="Observation" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))

                                                }
                                            </tbody>
                                        )
                                    }
                                </table>
                            </div>
                           
                        </div>
                    </div>
                </div>
                {
                    showAddObservation ? <AddObservation eleve={selectedEleve} onClose={closeAddObservation}/> : <></>
                }

            </div>
        </>
    )
}

export default AddAbsence