import React from 'react'

const Header = ({ category, title, icon, titleAr }) => {
  return (
    <div className='w-full'>
      <div  className="mb-10 mt-3 w-full">
        
        <div className="flex gap-2 w-full">
          <div className=" flex w-auto h-auto text-3xl font-bold tracking-tight text-slate-900 ">
            {icon}
          </div>
          <div className="flex w-full justify-between">
            <p className="text-xl font-bold tracking-tight text-slate-900">
              {title} 
            </p>
            <p className="text-xl font-bold tracking-tight text-slate-900">
               {titleAr}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
